import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory, Route } from "react-router-dom";
import { checkPropTypes } from "prop-types";
import axios from "axios";


const RequireAuth = ({ children, backEndLoginURL }) => {
  const { authService, authState } = useOktaAuth();
  const history = useHistory();
  if (!authState.isAuthenticated) {
    
    if (!authState.isPending) {
  
      // replacing this with a reference to the backend login url so that we can login to both frontend and backend at once
      const fromUri = history.createHref(history.location);
      authService.login(backEndLoginURL + "?returnUrl=" + fromUri);
    }
    return null;
  }
  else
  {
  
    axios.get(`/api/session/backendauthsuccess`).then((res) => {
      const apidata = res.data;
    })
    .catch((error) => {
      const fromUri = history.createHref(history.location);
      authService.login(backEndLoginURL + "?returnUrl=" + fromUri);

    })

    
  }


  return <React.Fragment>{children}</React.Fragment>;
};

const DMSecureRoute = ({ component, ...props }) => {
  const PassedComponent =
    component ||
    function () {
      return null;
    };
  const WrappedComponent = () => (
    <RequireAuth backEndLoginURL={props.backEndLoginURL}>
      <PassedComponent />
    </RequireAuth>
  );
  return (
    <Route
      {...props}
      render={() =>
        props.render ? (
          props.render({ ...props, component: WrappedComponent })
        ) : (
          <WrappedComponent />
        )
      }
    />
  );
};

export default DMSecureRoute;
