var url = "";
var arr = "";
var protocol_host_port = "";
var hostname = window.location.hostname;
console.log("hostname" + hostname);
var client_id = "";
var function_app_host = "";
switch (hostname) {
  case "localhost":
    client_id = "0oaawik0kPIHb5GLT4x6"; //dev
    function_app_host = "http://localhost:7071";
    break;
  case "cloudroboticslocal.coupadata.com":
    client_id = "0oaawik0kPIHb5GLT4x6"; //dev
    function_app_host = "http://localhost:7071";
    break;
  case "cloudroboticsdev.azurewebsites.net":
    client_id = "0oaawik0kPIHb5GLT4x6"; //dev
    function_app_host = "https://cloudroboticsfunctionsdev.azurewebsites.net";
    break;
  case "cloudroboticstest.azurewebsites.net":
    client_id = "0oaawik0kPIHb5GLT4x6"; //dev
    function_app_host = "https://cloudroboticsfunctionstest.azurewebsites.net";
    break;
  case "cloudroboticsstaging.azurewebsites.net":
    client_id = "0oaawija39mhvequN4x6"; //uat
    function_app_host = "https://cloudroboticsfunctionsstaging.azurewebsites.net";
    break;
  case "cloudrobotics.azurewebsites.net":
    client_id = "0oaawijcocZ4JcedP4x6";  //prod
    function_app_host = "https://cloudroboticsfunctions.azurewebsites.net";
    break;
  case "sidewalklabs.coupadata.com":
    client_id = "0oaawijcocZ4JcedP4x6"; //prod
    function_app_host = "https://cloudroboticsfunctions.azurewebsites.net";
    break;
  default:
    client_id = "0oaawijcocZ4JcedP4x6"; //prod <-- this should work and not require each customer url to be setup for auth to work
    function_app_host = "https://cloudroboticsfunctions.azurewebsites.net";
}
url = window.location.href;
arr = url.split("/");
protocol_host_port = arr[0] + "//" + arr[2];
console.log("protocol_host_port" + protocol_host_port);

console.log("starting env");
var oidc_str = process.env.REACT_APP_OIDC;
var oidc_str_2 = oidc_str.replace("{OIDC_CLIENTID}", client_id);
var oidc_str_3 = oidc_str_2.replace("{OIDC_PROTOCOL_HOST_PORT}", protocol_host_port);
console.log(oidc_str);
console.log(oidc_str_2);
console.log(oidc_str_3);
var oidc_json = JSON.parse(oidc_str_3);
const env2 = {
  function_app_host: function_app_host,
  oidc: oidc_json,
  resourceServer: {
    messagesUrl: 'https://localhost:44314/api/messages',  // these are not implemented
    userUrl: 'https://localhost:44314/api/user', // these are not implemented
  },
};
export default env2
