import React, { Component, useState, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import config from "./config";
//DMSecureRoute is a rewrite of the Okta SecureRoute which allows us to also login to the backend
import DMSecureRoute from "./DMSecureRoute";
import Login from "./components/Pages/Login";
import "./App.scss";
import { ActAsProvider } from "./components/CoupaSmartsheet/components/GlobalState/ActAsContext";


function App() {

  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
  );

  // Containers
  const DefaultLayout = React.lazy(() => import("./DefaultLayout"));
    return (
      <BrowserRouter>
        <Security {...config.oidc}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <DMSecureRoute
                backEndLoginURL="/login2/Login"
                path="/login"
                exact
                component={Login}
              />
              <Route path="/implicit/callback" component={LoginCallback} />

              <ActAsProvider>
              <DMSecureRoute
                backEndLoginURL="/login2/Login"
                path="/"
                name="Home"
                component={DefaultLayout}
                />
              </ActAsProvider>
            </Switch>
          </React.Suspense>
        </Security>
      </BrowserRouter>
    );
  }
export default App;
